/** @format */

import { Box, Container, Grid, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
export default function MobileScreenBottomBar() {
  // Data structure for categories and links
  const categories = [
    {
      title: "Legal",
      links: [
        { text: "Contact Us", url: "/contact-us" },
        { text: "Privacy Policy", url: "/privacy-policy" },
        { text: "Deletion Process", url: "/deletion-process" },
      ],
    },
  ];

  return (
    <Box
      sx={{
        width: "100%",
        paddingTop: 5,
        paddingBottom: 5,
        marginTop: 5,
        backgroundColor: "#0066FF",
        display: { xs: "flex", md: "none" },
        flexDirection: "column",
        justifyContent: "center",
      }}
    >
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",

          width: "100%",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",

            width: 184,
            justifyContent: "space-evenly",
          }}
        >
          <a href="https://www.facebook.com/profile.php?id=61553983282959">
            <FacebookIcon sx={{ color: "#FFFFFF", fontSize: 20 }} />
          </a>

          <a href="https://www.linkedin.com/company/98691625">
            <LinkedInIcon sx={{ color: "#FFFFFF", fontSize: 20 }} />
          </a>

          <a href="https://www.instagram.com/reshapeapp">
            <InstagramIcon sx={{ color: "#FFFFFF", fontSize: 20 }} />
          </a>

          <a href="https://twitter.com/reshapeapp">
            <TwitterIcon sx={{ color: "#FFFFFF", fontSize: 20 }} />
          </a>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",

          width: "100%",
          justifyContent: "center",
          alignItems: "center",
          marginTop: 3,
        }}
      >
        <Box sx={{ width: 328 }}>
          {/* Accordion section starts */}
          {categories.map((category, index) => (
            <Accordion
              key={index}
              sx={{
                backgroundColor: "transparent", // Ensures no background color
                color: "white",
                boxShadow: "none", // Removes box-shadow

                "&::before": {
                  // Removes the pseudo-element used for borders in MUI
                  display: "none",
                },
                "&.Mui-expanded": {
                  border: "1px solid #FFFFFF",

                  borderRadius: 5,
                  background:
                    "linear-gradient(to bottom, rgba(255, 255, 255, 0.2) 0%, rgba(255, 255, 255, 0) 100%)", // Faded white effect
                  transition: "background 0.3s ease", // Smooth transition for the background
                },
              }}
            >
              <AccordionSummary
                expandIcon={
                  <ExpandMoreIcon sx={{ color: "white", fontSize: 30 }} />
                }
              >
                <Typography
                  sx={{
                    fontWeight: 700,
                    fontSize: 16,

                    fontFamily: "MyOwnFont,PublicSans",
                    color: " #FFFFFF ",
                  }}
                >
                  {category.title}
                </Typography>
              </AccordionSummary>
              <AccordionDetails
                sx={{
                  display: "flex",
                  flexFlow: "column",
                  justifyContent: "flex-start",
                  marginLeft: 2,
                }}
              >
                {category.links.map((link, linkIndex) => (
                  <Typography
                    key={linkIndex}
                    sx={{
                      marginBottom: 1,
                      color: "white",
                      textDecoration: "none",
                      fontWeight: 400,
                      fontSize: 15,

                      fontFamily: "MyOwnFont,PublicSans",
                    }}
                    component="a"
                    href={link.url}
                  >
                    {link.text}
                  </Typography>
                ))}
              </AccordionDetails>
            </Accordion>
          ))}
        </Box>
      </Box>
    </Box>
  );
}

/** @format */

import { useState } from "react";
import { BrowserRouter, Route, Routes, Link } from "react-router-dom";
import {
  AppBar,
  Toolbar,
  Typography,
  Button,
  Drawer,
  List,
  ListItem,
  IconButton,
  Hidden,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import PrivacyPolicy from "./PrivacyPolicy";
import DeletionProcess from "./DeletionProcess";
import Home from "./Home";

import ContactUs from "./page/ContactUs";
function App({ navigation }: any) {
  const [drawerOpen, setDrawerOpen] = useState(false);

  const toggleDrawer = (open: boolean) => () => {
    setDrawerOpen(open);
  };

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy navigation={navigation} />}
        />
        <Route path="/contact-us" element={<ContactUs />} />
        <Route path="/deletion-process" element={<DeletionProcess />} />

        <Route path="*" element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;

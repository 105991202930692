/** @format */

import { Box, Button, Container, Grid, Typography } from "@mui/material";
import FacebookIcon from "@mui/icons-material/Facebook";
import YouTubeIcon from "@mui/icons-material/YouTube";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";
export default function BigscreenBottomBar() {
  return (
    <Box
      sx={{
        width: "100%",
        marginTop: 10,
        height: 314,
        backgroundColor: "#0066FF",
        display: { xs: "none", md: "flex" },
        flexDirection: "row",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      {/* Section 1 */}
      <Grid
        container
        sx={{
          display: "flex",
          flexDirection: "column",

          justifyContent: "space-evenly",
          alignItems: "center",
          height: "100%",
          width: { md: "100%", xs: "90%" },
        }}
      >
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            width: "90%",
            justifyContent: "flex-start",

            // marginLeft: { xs: 0, sm: 10, md: 15 },
          }}
        >
          <Box
            sx={{
              width: { xs: "33%", md: 160 },
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "50%",
            }}
          >
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 700,
                color: "#FFFFFF",
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              Legal
            </Typography>
            <a
              type="text"
              href="/contact-us"
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "#FFFFFF",
                  marginTop: 1,
                  fontFamily: "MyOwnFont,PublicSans",
                }}
              >
                Contact Us
              </Typography>
            </a>
            <a
              type="text"
              href="/privacy-policy"
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "#FFFFFF",
                  marginTop: 1,
                  fontFamily: "MyOwnFont,PublicSans",
                }}
              >
                Privacy Policy
              </Typography>
            </a>
            <a
              type="text"
              href="/deletion-process"
              style={{ textDecoration: "none" }}
            >
              <Typography
                sx={{
                  fontSize: 15,
                  fontWeight: 400,
                  color: "#FFFFFF",
                  marginTop: 1,
                  fontFamily: "MyOwnFont,PublicSans",
                }}
              >
                Deletion Process
              </Typography>
            </a>
          </Box>
          {/* <Box
            sx={{
              width: { xs: "33%", md: 160 },

              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "50%",
            }}
          >
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 700,
                color: "#FFFFFF",
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              Catagory
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 400,
                color: "#FFFFFF",
                marginTop: 1,
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              First Link
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 400,
                color: "#FFFFFF",
                marginTop: 1,
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              Second Link
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 400,
                color: "#FFFFFF",
                marginTop: 1,
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              Third Link
            </Typography>
          </Box> */}
          {/* <Box
            sx={{
              width: { xs: "33%", md: 160 },

              display: "flex",
              flexDirection: "column",
              justifyContent: "space-evenly",
              height: "50%",
            }}
          >
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 700,
                color: "#FFFFFF",
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              Catagory
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 400,
                color: "#FFFFFF",
                marginTop: 1,
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              First Link
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 400,
                color: "#FFFFFF",
                marginTop: 1,
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              Second Link
            </Typography>
            <Typography
              sx={{
                fontSize: 15,
                fontWeight: 400,
                color: "#FFFFFF",
                marginTop: 1,
                fontFamily: "MyOwnFont,PublicSans",
              }}
            >
              Third Link
            </Typography>
          </Box> */}
        </Container>
        <Container
          sx={{
            display: "flex",
            flexDirection: "row",

            width: "90%",
            justifyContent: "flex-start",

            // marginLeft: { xs: 0, sm: 10, md: 15 },
          }}
        >
          <Box
            sx={{
              width: "100%", // Ensure Box takes full width on all screen sizes
              maxWidth: { md: 510 },
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="https://www.facebook.com/profile.php?id=61553983282959"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "NONE",
                }}
              >
                <FacebookIcon sx={{ color: "#FFFFFF", fontSize: 15 }} />
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 400,
                    color: "#ffffff",
                    marginLeft: 1,
                    fontFamily: "MyOwnFont,PublicSans",
                  }}
                >
                  Facebook
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="https://www.linkedin.com/company/98691625"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "NONE",
                }}
              >
                <LinkedInIcon sx={{ color: "#FFFFFF", fontSize: 15 }} />
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 400,
                    color: "#ffffff",
                    marginLeft: 1,
                    fontFamily: "MyOwnFont,PublicSans",
                  }}
                >
                  linkedin
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="https://www.instagram.com/reshapeapp"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "NONE",
                }}
              >
                <InstagramIcon sx={{ color: "#FFFFFF", fontSize: 15 }} />
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 400,
                    color: "#ffffff",
                    marginLeft: 1,
                    fontFamily: "MyOwnFont,PublicSans",
                  }}
                >
                  Instagram
                </Typography>
              </a>
            </Box>
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <a
                href="https://twitter.com/reshapeapp"
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  textDecoration: "NONE",
                }}
              >
                <TwitterIcon sx={{ color: "#FFFFFF", fontSize: 15 }} />
                <Typography
                  sx={{
                    fontSize: 15,
                    fontWeight: 400,
                    color: "#ffffff",
                    marginLeft: 1,
                    fontFamily: "MyOwnFont,PublicSans",
                  }}
                >
                  X
                </Typography>
              </a>
            </Box>
          </Box>
        </Container>
      </Grid>
    </Box>
  );
}
